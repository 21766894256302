import { css, Global, withTheme } from '@emotion/react'

import * as React from 'react'

const GlobalCss = withTheme(() => (
  <Global
    styles={css`
      html {
        scroll-behavior: smooth;
      }

      h1 {
        text-transform: uppercase;
        line-height: 28px;
        letter-spacing: 4px;
      }

      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: 'PP Object Sans', Helvetica;
      }

      h1.leshen-typography-h4 {
        font-family: 'PP Object Sans', Helvetica;
      }

      a:visited {
        color: inherit !important;
      }

      .leshen-form {
        width: auto !important;
      }
      .top-bar {
        .top-nav {
          a {
            border-left: 1px solid #fff;
            &:first-of-type {
              border-left: none;
            }
          }
        }
      }

      .show-desktop,
      .show-mobile {
        display: none;
      }

      @media (min-width: 768px) {
        .show-desktop {
          display: block;
        }
      }

      @media (max-width: 768px) {
        .show-mobile {
          display: block;
        }
      }

      .inline {
        display: inline;

        .leshen-brandy {
          margin: 0;
          display: inline;
        }
      }
    `}
  />
))

export default GlobalCss
